<template>
  <div>
    <div class="product_pc">
        <div class="part3_img_div">
            <img src="../assets/img/group4.png" />
        </div>
        <div class="part3_one fsInit" >
          <img src="../assets/img/pen.png" alt="">
            <div class="fs60 mt10">关于我们</div>
            <div style="margin-top: 20px" class="product_font_style pr40 lineHeight40">梦溪云算提供云计算、云服务、大数据服务，为企业降本增效，科技赋能，加快创新，提升竞争力，将“数据资产”转化为企业“运营 生产力”和“核心竞争力”，为企业“数字化生产”提供可信赖的服务。</div>
            <div class="fs16em mt10 "><span class="slogenTitle">梦溪云算<span style="font-size:16px" class="RIcon" >®</span></span>  <span class="slogenTitle">源于技术、忠于服务</span></div>
        </div>
    </div>
  </div>
</template>
<script>
    export default {
        name: 'About',
        data () {
            return {

            }
        }
    }
</script>

<style scoped lang="scss">
  *{
    box-sizing: border-box;
    margin:0;
    padding:0;
  }
  .product_pc {
        display: flex;
        width: 1400px;
        margin: 0 auto;
        background: #F2F6FC;
        align-items: center;
    }
  .part3_img_div{
      width: 40%;
      float: left;
      display: block;
      text-align: center;
  }
  .part3_img_div img{
      height: 640px;
      visibility: hidden;
  }
  .part3_one {
      width: 60%;
      float: left;
      text-align: left;
  }
  .part3_one img{
      height: 80px;

  }
</style>