<template>
    <div id="app">
        <div class="main">
            <div class="header">
                <div class="logo_img">
                    <div  @click="logoClick" style="display:flex; align-items: center; float:left; height: 90px;">
                        <div class="header-logo">
                            <img id="header-img" src="../assets/img/logo.png" >
                        </div>
                    </div>
                    
                </div>
                <div id="menu_index">
                    <!-- web端菜单导航 -->
                    <!-- <el-menu :default-active="activeIndex" class="el-menu-demo" id="homeMenu" menu-trigger="click" text-color="#333" style="height: 60px; border:0;" mode="horizontal" @select="handleSelect">
                        <el-submenu index="2">
                            <template slot="title">服务</template>
                            <el-menu-item index="2-1">计算</el-menu-item>
                            <el-menu-item index="2-2">存储</el-menu-item>
                        </el-submenu>
                        <el-submenu index="3">
                            <template slot="title">培训</template>
                            <el-menu-item index="3-1">在线课程</el-menu-item>
                            <el-menu-item index="3-2">直播课程</el-menu-item>
                            <el-menu-item index="3-3">报名入口</el-menu-item>
                            <el-menu-item index="3-4">听课入口</el-menu-item>
                        </el-submenu>
                    </el-menu> -->
                 </div>
                <div v-if="isLogin" class="personGroup">
                    <el-dropdown >
                        <span class="el-dropdown-link pointer">
                            username<i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown" >
                            <el-dropdown-item  key="userInfo">个人信息</el-dropdown-item>
                            <el-dropdown-item key="companyInfo">企业信息</el-dropdown-item>
                            <el-dropdown-item key="userManage">企业用户管理</el-dropdown-item>
                            <el-dropdown-item key="myCourse">我的课程</el-dropdown-item>
                            <el-dropdown-item class="logout"><div @click="closeVisible = true"><img src="../assets/img/logout.svg" /><span>退出</span></div></el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
                <div v-else class="personGroup">
                    <el-button type="text" style="margin-right: 20px;" class="header-title" @click="login">登录</el-button>
                    <!-- <el-button type="primary" class="header-title" @click="register" round>注册</el-button> -->
                </div>
            </div>
            <div class="container">
                <keep-alive>
                    <router-view></router-view>1
                </keep-alive>
            </div>

            <div id="bottomWrap" class="cooperation">
                <img :src="bottomBg" alt="" id="bgImg">
                <div id="bgColor"></div>
                <div class="mainMenu flexRow">
                    <div class="menuBox" v-for="(item,i) of menuList" :key="i">
                        <img :src="item.img" alt="">
                        <div class="fs16em fontWight">{{item.title}}</div>
                        <div class="flexColumn mt10"><div class="product_font_style p10 pointer fontWight" @click="linkToPath(ele)" v-for="(ele,j) of item.subItem" :key="j">{{ele.name}}</div></div>
                    </div>
                </div>
                
                <div style="margin: 0px auto; padding: 20px 0px; position: absolute; bottom: 0px; left: 0px; right: 0px;">
                    <a  target="_blank" href=" http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802039060" style="display: inline-block; text-decoration: none; height: 20px; line-height: 20px;">
                        <img src="../assets/img/jinghui.png" style="float:left"/>
                        <p  style="float: left; height: 20px; line-height: 20px; margin: 0px 0px 0px 5px; color: rgb(255, 255, 255);">2022 北京亿邦华能石油科技发展有限公司 | 京公网安备 11010802039060号 | </p>
                    </a>
                    <a  target="_blank" href="https://beian.miit.gov.cn/Integrated/index" style="display: inline-block; text-decoration: none; height: 20px; line-height: 20px;">
                        <p  style="float: left; height: 20px; line-height: 20px; margin: 0px 0px 0px 5px; color: rgb(255, 255, 255);">京ICP备19059765号-3</p>
                    </a>
                    </div>
   
            </div>
        </div>
        <div id="back_to_top" ref="btn" @click="backTop" style="display: none;">
            <p style="font-size: 0.625em; font-weight: bold">TOP</p>
            <!-- <img src="../assets/other/launch.png" style="height: 45px;width: 35px" /> -->
        </div>
        <el-dialog
            title="提示"
            :visible.sync="closeVisible"
            width="300px"
            center>
            <span>是否确认退出？</span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="closeVisible = false" size="small">否</el-button>
                <el-button type="primary" size="small" @click="logout">是</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "Layout",
        data() {
            return {
                isLogin:false,
                closeVisible:false,
                activeIndex: '1',
                scroll: '',
                timer: '',
                currentMenu:'首页',
                bottomBg:require('../assets/img/bottomBg.png'),
                menuList: [
                    {
                        img: require('../assets/img/icon1.png'),
                        title: "梦溪云算",
                        subItem: [{name:"关于我们",path:'/about'},{name:"联系我们",path:'/communication'}]
                    },
                    {
                        img: require('../assets/img/icon2.png'),
                        title: "产品",
                        // subItem: [{name:"计算",path:'/calculate'},{name:"存储",path:'/storage'}] 
                    },
                    {
                        img: require('../assets/img/icon3.png'),
                        title: "管理账户",
                        subItem: ["管理中心", "用户管理","账单管理","资源管理"]
                    },
                    {
                        img: require('../assets/img/icon4.png'),
                        title: "开始构建",
                        subItem: ["创建账户", "账户管理"]
                    },
                    {
                        img: require('../assets/img/icon5.png'),
                        title: "客户支持",
                        subItem: [" "]
                    }
                ],
            };
        },
        methods: {
            toPersonalPage(v){
                console.log(222)
                this.$router.push({path:'/PersonalManage',query:{curItem:v}})
            },
            logout(){
                localStorage.removeItem('token')
                this.isLogin = false
                this.closeVisible = false
                this.$router.push('/');
            },
            linkToPath(item){
                this.$router.push(item.path)
            },
            handleSelect(key) {
                switch (key){
                    case '1':
                        this.$router.push({name: "Index"});
                        break;
                    case '2-1':
                        this.$router.push('/calculate');
                        break;
                    case '2-2':
                        this.$router.push('/storage');
                        break;
                    case '4':
                        this.$router.push('/driverDownLoad');
                        break;
                    default:
                        this.$router.push('/communication');
                        break;
                }
            },
            handleSelectMobile(key) {
                switch (key){
                    case '1':
                        this.$router.push({name: "Index"});
                        this.currentMenu='首页';
                        break;
                    case '2-1':
                        this.$router.push({name: "Index"});
                        window.location.hash = "#产品微观";
                        this.currentMenu='首页';
                        break;
                    case '2-2':
                        this.$router.push({name: "Fruit"});
                        this.currentMenu='生鲜果蔬';
                        break;
                    case '2-3':
                        this.$router.push({name: "Fashion"});
                        break;
                    case '3':
                        this.$router.push({name: "Index"});
                        window.location.hash = "#产品特点";
                        this.currentMenu='首页';
                        break;
                    case '4':
                        this.$router.push({name: "Index"});
                        window.location.hash = "#合作伙伴";
                        this.currentMenu='首页';
                        break;
                    case '5':
                        this.$router.push({name: "Index"});
                        window.location.hash = "#关于我们";
                        this.currentMenu='首页';
                        break;
                }
            },
            handleScroll(){
                this.scroll = document.documentElement.scrollTop + document.body.scrollTop; //获取滚动后的高度
                if( this.scroll >600 ){  //判断滚动后高度超过400px,就显示
                    this.$refs.btn.style.display = 'block';
                }else{
                    this.$refs.btn.style.display = 'none'
                }
            },
            backTop(){ //当点击标签的时候,使用animate在200毫秒的时间内,滚到顶部
                this.timer = setInterval(() => {
                    let osTop = document.documentElement.scrollTop || document.body.scrollTop;
                    let ispeed = Math.floor(-osTop / 5)
                    document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed;
                    if (osTop === 0) {
                        clearInterval(this.timer)
                    }
                }, 30)
            },
            login(){
                window.open('http://www.mxcc.cloud/login','_blank')
                // this.$router.push('/communication');
                // this.$router.push('/login');
            },
            register(){
                this.$router.push('/communication');
                // this.$router.push('/register');
            },
            logoClick(){
                this.$router.push({name: "Index"});
                this.currentMenu='首页';
            }
        },
        mounted() {
            const token = localStorage.getItem('token')
            if (token) {
                this.isLogin = true
            }
            window.addEventListener('scroll', this.handleScroll)
            window.addEventListener('setItem',(e)=>{
                if(e.key==='token'){
                    this.isLogin = true

                }
            })
        },
    }
</script>
<style>
#homeMenu{
    background-color:#323f4b;
}
#homeMenu *{
    color: #fff !important;
}
#homeMenu .el-submenu__title:hover{
    background-color:#323f4b ;
}
.el-menu--popup{
    min-width: 92px !important;
}
.logout{
    display: flex;
    border-top: 1px solid rgb(228, 228, 228);
}
.logout img{
    margin-right: 6px;
}
</style>
<style scoped lang="scss">
    .container{
        overflow: hidden;
    }
    .logo_img{
        float:left;
        width: 30%;
    }
    .header-logo{
        cursor: pointer;
    }
    #back_to_top{
        position: fixed;
        bottom: 50px;
        right: 30px;
        cursor: pointer
    }
    .header-title{
        font-size: 1em;
    }
    .company_name{
        font-weight: normal;
        font-size: 1em;
    }
    .footer_font{
        font-size: 1em;
    }
    #menu_index{
        float: left; width: 50%;margin-top:30px;
    }
    .personGroup{
        float: right; display: flex;align-items: center; height: 100%;
    }
    .header{
        margin: auto;
        height: 90px;
        padding: 0 10%;
        background: #323f4b;
    }
    #bottomWrap{
        width: 1400px;
        margin: 0 auto;
        position: relative;
        height: 600px;
        text-align: center;
    }
    #bottomWrap #bgImg{
        position: absolute;
        height: 200px;
        left: 0;
        right: 0;
        top: 0;
        width: 100%;
        z-index: -5;
    }
    #bottomWrap #bgColor{
        position: absolute;
        top: 200px;
        left: 0;
        right: 0;
        bottom: 0;
        background: #323f4b;
        z-index: -5;
    }
    #bottomWrap .mainMenu{
        justify-content: space-around;
        padding-top: 65px;
    }
    .menuBox img{
        width: 90px;
    }
    @media screen and (max-device-width: 415px) {
        .logo_img{
            float:left;
            width: auto;
            cursor: pointer;
        }
        #back_to_top{
            position: fixed;
            bottom: 50px;
            right: 5px;
            cursor: pointer
        }
        .footer_font{
            font-size: 0.875em;
        }
        #menu_index{
            float: right;
            width: auto;
            height: 100%
        }
        .mobile_menu{

        }
    }
    .main{
        margin: auto;
        float: left;
        width: 100%;
        border: 0;
    }
    #header-img{
        /* width: 120px; */
        height: 90px;
        float: left;
        margin: auto;
    }
    .header-logo{
        /* height: 50px; */
        align-self: center;
    }
    .header-important{
        float: left;
        margin-left: 6px;
        align-self: center;
    }

    .footer{
        display: flex;
        align-items: center;
        margin: 10px auto;
        width: 1400px;
    }
    a{text-decoration: none;}
    a:visited{text-decoration: none;}
    a:hover {text-decoration: none;}
    a:active{text-decoration:none;}
    .el-dropdown-link{
        color: #fff;
    }
</style>