<template>
  <div class="box">
    <div id="register">
        <h2>注册<div class="otherPage">已有账号，<span class="pointer" @click="$router.push('/login')">点此登录</span></div></h2>
        <el-radio-group @change="changeType" v-model="userType">
            <el-radio-button label="personal">个人</el-radio-button>
            <el-radio-button label="company">企业</el-radio-button>
        </el-radio-group>
        <el-form ref="form" :model="form" label-width="32%">
        <template v-if="userType==='company'">
          <el-form-item label="企业名称:">
              <el-input v-model="form.companyName"></el-input>
          </el-form-item>
          <el-form-item label="统一社会信用代码:">
              <el-input v-model="form.companyId"></el-input>
          </el-form-item>
        </template>
        <el-form-item label="用户名:">
            <el-input v-model="form.username"></el-input>
        </el-form-item>
        <el-form-item label="手机号:">
            <el-input v-model="form.telephone" type="number" :controls="false" ></el-input>
        </el-form-item>
        <el-form-item label="验证码:">
            <el-input v-model="form.captcha" type="number" :controls="false"><el-button slot="append" >获取验证码(60s)</el-button></el-input>
        </el-form-item>
        <el-form-item label="密  码:">
            <el-input v-model="form.password" type="password" :show-password="true"></el-input>
        </el-form-item>
        </el-form>
        <el-button type="primary" round @click="register" class="btn">注册</el-button>
    </div>
  </div>
</template>

<script>

export default {
  data () {
    return {
      form: {
        username: '',
        telephone: ''  
      },
      isnull: false,
      userType:'company'
    };
  },
 
  methods: {
    changeType(v){
        this.userType = v
    },
    register() {
      if(this.form.username == '') {
        this.$message.error('用户名不能为空');
      }else if(this.form.password == '') {
        this.$message.error('密码不能为空');
      }else{
        this.axios.post('/register', {
          params: {
            name: this.form.username,
            password: this.form.password
          }
        }).then(res => {
          // console.log(res.data.message);
          if(res.data.status == 200) {
            this.$alert('是否返回登录页面', '注册成功', {
              confirmButtonText: '确定',
              callback: action => {
                this.$router.push('/login')
              }
            })
          }else if(res.data.status == 202) {
            this.$alert('用户名已存在', '注册失败', {
              confirmButtonText: '确定',
              callback: action => {
                this.form.username = '',
                this.form.password = ''
              }
            })
          }else{
            console.log(res.message);
          }
        }).catch(err => {
          console.log('操作失败' + err);
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.box {
    height: 800px;
    position: relative;
    width: 1400px;
    margin: 0 auto;
    display: flex;
    align-items: center;
}
 #register {
    /* height: 565px; */
    width: 400px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.25);
    background: #ffffff;
    opacity: 0.85;
    position: absolute;
    /* top: 0;
    bottom: 0; */
    margin: auto;
    right: 0;
    left: 0;
    text-align: center;
    display: flex;
    flex-direction: column;/*横向*/
    padding: 30px;
  }
  h2{
    text-align: left;
    overflow: hidden;
  }
  .btn {
    width: 60%;
    margin: auto;
  }
  .reg {
    color: red;
    position: absolute;
    bottom: 10px;
    right: 10px;
    cursor:pointer;
  }
</style>
<style >
#register .el-radio-group{
      margin: 20px 0;
  }
  #register .el-radio-group label{
      width: 50%;
  }
  #register .el-radio-group label span{
      width: 100%;
      display: inline-block;
  }
  	/* Chrome */
    input::-webkit-outer-spin-button {
      -webkit-appearance: none;
    }
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
    /* Firefox */
    input[type='number']{
        -moz-appearance: textfield;
    }
</style>