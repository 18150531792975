<template>
  <div>
    <div class="product_pc">
        <div>
          
        </div>
    </div>
  </div>
</template>
<script>
    export default {
        name: 'Developing',
        data () {
            return {

            }
        }
    }
</script>

<style scoped lang="scss">
  *{
    box-sizing: border-box;
    margin:0;
    padding:0;
  }
  .product_pc {
        display: flex;
        width: 1400px;
        margin: 0 auto;
        background: #F2F6FC;
        align-items: center;
        height: 800px;
        flex-direction:column;
        justify-content: center;
    }
    img{
      margin: 0 auto;
    }
    .product_pc div{
      color: #a7acb3;
    }
</style>