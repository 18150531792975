import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/common.css'
import './assets/style.css'
// import App from './App.vue';
import router from './router'
import Layout from './components/Layout.vue';

Vue.use(ElementUI);

Vue.prototype.resetSetItem = function (key,newValue) {
  // 
  let newStorageEvent = document.createEvent('StorageEvent')
  const storage = {
    setItem:function (k,val) {
      localStorage.setItem(k,val)
      newStorageEvent.initStorageEvent('setItem',false,false,k,null,val,null,null)
      window.dispatchEvent(newStorageEvent)
    }
  }
  return storage.setItem(key,newValue)
}

new Vue({
  el: '#app',
    router,
  components:{ Layout },
    template: '<Layout/>',
  render: h => h(Layout)
});
