<template>
  <div class="userManage">
    <div class="buttonGroup">
      <el-button size="mini" @click="userVisible =true">新增</el-button>
      <el-button size="mini">批量导入</el-button>
      <el-button size="mini">角色</el-button>
      <el-button size="mini">权限</el-button>
    </div>
    <el-table :data="tableData" style="width: 100%">
      <el-table-column type="selection" width="50" align="center"/>
      <el-table-column label="序号" width="50" align="center"><div slot-scope="scope">{{scope.$index+1}}</div></el-table-column>
      <el-table-column prop="name" label="姓名" min-width="180"/>
      <el-table-column prop="phone" label="电话" min-width="180"/>
      <el-table-column prop="department" min-width="180" label="部门"/>
      <el-table-column width="80" align="center" label="操作">
        <div class="optGroup">
          <i class="el-icon-edit"/>
          <i class="el-icon-delete"/>
        </div>
      </el-table-column>
    </el-table>
    <el-dialog
      title="提示"
      :visible.sync="userVisible"
      width="30%"
      :before-close="handleClose">
      <span>这是一段信息</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      userVisible:false,
      tableData:[
        {
          name:'张安',
          id:'123',
          phone:15276766609,
          departmentId:'01',
          department:'行政部',
        },
        {
          name:'张蓝',
          id:'222',
          phone:15276766609,
          departmentId:'02',
          department:'技术部',
        },
        {
          name:'李立',
          id:'333',
          phone:15276766609,
          departmentId:'03',
          department:'运维部',
        },
      ]
    };
  },

  methods: {
    handleClose(){}
  }
}
</script>

<style scoped lang="scss" >
.userManage{

}
.optGroup {
  i{
    display: inline-block;
    margin: 0 5px;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  
}
.buttonGroup{
  display: flex;
  padding: 20px 10px;
}
</style>