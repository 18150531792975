<template>
  <div>
    <div class="infoBar">
      <div class="centerPart">
        <div class="avatarbox">
          <img src="../assets/img/group4.png" alt="">
        </div>
        <div class="userName">Username</div>
      </div>
    </div>
    <div class="box" id="manage">
        <el-tabs tab-position="left" style="height: 800px;">
          <el-tab-pane label="个人信息"><div class="paneContent">个人信息</div></el-tab-pane>
          <el-tab-pane label="企业信息"><div class="paneContent"><Information/></div></el-tab-pane>
          <el-tab-pane label="企业用户管理"><div class="paneContent"><CompanyUserManage/></div></el-tab-pane>
          <el-tab-pane label="我的课程"><div class="paneContent">我的课程</div></el-tab-pane>
        </el-tabs>
    </div>
  </div>
</template>

<script>
import Information from '../components/Information'
import CompanyUserManage from '../components/CompanyUserManage'
export default {
  components:{
    Information,CompanyUserManage
  },
  data () {
    return {
      
    };
  },

  methods: {
    
    
  }
}
</script>

<style scoped lang="scss" >
.infoBar{
  width: 100%;
  height: 100px;
  background: #beeaff;
  margin-bottom: 50px;
  .centerPart{
    position: relative;
    width: 1400px;
    margin: 0 auto;
    display: flex;
    .userName{
      line-height: 100px;
      margin-left: 40px;
    }
    .avatarbox{
      box-shadow: 0 2px 12px 0 #687d8967;
      width: 100px;
      height: 100px;
      border-radius: 50%;
      overflow: hidden;
      margin-left: 20px;
      margin-top: 20px;
      img{
        width: 100%;
        height: 100%;
      }
    }
  }
}
#manage{
  height: 600px;
    position: relative;
    width: 1400px;
    margin: 0 auto;
}
.paneContent{
  width: 1267px;
  height: 800px;
  position: relative;
}
</style>