<template>
<!-- 计算 -->
  <div class="commonMain">
    <div class="alignC fs60 p40">安全、稳定，高可用的产品</div>
    <div class="p80 mainBgc relative">
      <div class="flexRow">
        <div class="fsInit left pr40">
          <div class="fs60">计算</div>
          <div class="fsInit flexRow mt40">
            <div class="titBox">S3</div>
            <div><img class="iconImg" src="../assets/img/calc.png" alt="" srcset=""></div>
          </div>
          <div class=" wordBox mt10 p40 lineHeight40 alignL">向用户提供虚拟化服务器（虚拟计算环境），根据用户需要调整计算容量，如实例数量、CPU数量、内存大小、存储大小、类型以及网络性能进行弹性缩放。</div>
        </div>
        <div class="right">
          <div class="fsInit">
            <div class="fs14em bold">弹性 WEB 级计算</div>
            <div class=" mt10 lineHeight40">借助 EC2 云服务器，您可以在几分钟（而不是几小时或几天）内增加或减少容量。您可以同时管理一个、数百个，甚至数千个服务器实例。当然，因为这全是通过 Web 服务 API 控制，所以您的应用程序可根据其自身需求自动扩展和缩减。</div>
          </div>
          <div class="fsInit mt40">
            <div class="fs14em bold">完全控制</div>
            <div class=" mt10 lineHeight40">您可以完全控制您的云服务器/云主机实例。您拥有每个实例的根用户访问权，可以像与其他任何机器一样与这些实例互动。您可以在停止运行实例的同时将数据保存在引导分区，然后用 Web 服务 API 重启该实例。使用 Web 服务 API 还可以远程重启实例。您还可以访问实例控制台的输出。</div>
          </div>
          <div class="fsInit mt40">
            <div class="fs14em bold">经济实惠</div>
            <div class=" mt10 lineHeight40">依托于规模化基础设置，Amazon EC2 云服务器/云主机服务可为您带来 Amazon 的规模经济效益。您只需为您实际消耗的计算容量支付极低的费用。</div>
            <div class=" mt10">按需实例 预留实例 Spot实例 专用主机</div>
          </div>
        </div>
      </div>
      <img class="iconLeft" src="../assets/img/iconLeft.png" alt="" srcset="">
      <img class="iconRight" src="../assets/img/iconRight.png" alt="" srcset="">
    </div>
  </div>
</template>
<script>
    export default {
        name: 'Communication',
        data () {
            return {
            }
        }
    }
</script>

<style scoped lang="scss">
.flexRow{
  justify-content:space-around;
}
.titBox{
  font-size: 50px;
  background-color: #2b4663;
  border-radius: 10px;
  width: 120px;
  height: 120px;
  text-align: center;
  line-height: 120px;
  color: #fff;
}
.left{
  width: 40%;
  box-sizing: border-box;
}
.right{
  width: 60%;
  text-align: left;
  padding-left: 80px;
  border-left: #2b4663 1px solid;
  box-sizing: border-box;
}
.iconImg{
  width: 100px;
  margin-top: 10px;
}
.iconLeft{
  left: 20px;
  position: absolute;
  bottom: 20px;
  width: 60px;
}
.iconRight{
  right: 20px;
  position: absolute;
  bottom: 20px;
  width: 60px;
}
</style>