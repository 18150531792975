<template>
  <div class="box">
    <div id="login">
        <h2>登录<div class="otherPage">没有账号，<span class="pointer" @click="$router.push('/register')">点此注册</span></div></h2>
        <el-radio-group v-model="userType">
            <el-radio-button label="personal">个人</el-radio-button>
            <el-radio-button label="company">企业</el-radio-button>
        </el-radio-group>
        <template  v-if="!isCaptchaLogin">
          <el-form ref="passwordForm" :model="passwordForm" label-width="20%">
            <el-form-item label="用户名:">
                <el-input v-model="passwordForm.username"></el-input>
            </el-form-item>
            <el-form-item label="密  码:">
                <el-input v-model="passwordForm.password" type="password"></el-input>
            </el-form-item>
          </el-form>
        </template>
        <template v-else>
          <el-form ref="captchaForm" :model="captchaForm" label-width="20%">
            <el-form-item label="手机号:">
                <el-input v-model="captchaForm.phone"></el-input>
            </el-form-item>
            <el-form-item label="验证码:">
                <el-input v-model="captchaForm.captcha" type="number" :controls="false"><el-button slot="append" >获取验证码(60s)</el-button></el-input>
            </el-form-item>
          </el-form>
        </template>
        <div class="loginType">
          <div class="pointer mainColor" @click="changeLoginType">{{isCaptchaLogin?'账号密码登录':'验证码登录'}}</div>
        </div>
        <el-button type="primary" round @click="login" class="btn">登录</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      passwordForm: {
        username: '',
        password: '',
      },
      captchaForm: {
        phone: '',
        captcha: '',
      },
      userType:'company',
      isCaptchaLogin:false //是否验证码登录
    };
  },

  methods: {
    changeLoginType(){
      this.isCaptchaLogin = !this.isCaptchaLogin
      this.passwordForm = {username: '',password: '',}
      this.captchaForm = {phone: '',captcha: '',}
    },
    login() {
      this.$router.push({path:'/PersonalManage',query:{userType:this.userType}})
        this.resetSetItem('token','2222')
        return
      if(this.form.username == '') {
        this.$message.error('用户名不能为空');
      }else if(this.form.password == '') {
        this.$message.error('密码不能为空');
      }else{
        
        this.axios.get('/login', {
          params: {
            name: this.form.username,
            password: this.form.password
          }
        }).then(res=>{
          if(res.data.status == 200) {
            this.$router.push({
              path: '/home',
              query: {
                name: this.form.username
              }
            })
          }else{
            this.$alert('用户名或密码错误', '登录失败', {
              confirmButtonText: '确定',
              callback: action => {
                this.form.username = '',
                this.form.password = ''
              }
            });
          }
        }).catch(err=>{
            console.log("登录失败" + err);
        })
      }
    },
    register() {
      this.$router.push('/register')
    }
  }
}
</script>

<style scoped lang="scss" >
.box {
    height: 800px;
    position: relative;
    width: 1400px;
    margin: 0 auto;
    display: flex;
    align-items: center;
}
 #login {
    width: 350px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.25);
    background: #ffffff;
    opacity: 0.85;
    position: absolute;
    margin: auto;
    right: 0;
    left: 0;
    text-align: center;
    display: flex;
    flex-direction: column;/*横向*/
    padding: 30px;
  }
h2{
    text-align: left;
    overflow: hidden;
  }
  .btn {
    width: 60%;
    margin: auto;
  }
  .reg {
    color: red;
    position: absolute;
    bottom: 10px;
    right: 10px;
    cursor:pointer;
  }
  .loginType{
    display: flex;
    justify-content: end;
    margin-top: -15px;
    margin-bottom: 15px;
    div{
      font-size: 12px;
    }
  }
</style>
<style lang="scss">
#login {
  .el-radio-group{
      margin: 20px 0;
      label{
          width: 50%;
          span{
              width: 100%;
              display: inline-block;
          }
      }
  }
}
</style>