<template>
    <div style="float: left;width: 100%">
        <!-- <el-carousel :height=computeHeight indicator-position="outside">
        </el-carousel> -->
        <div class="flexColumn" :style="{height:computeHeight}">
            <div class="alignC slogenTitle" style="font-size:68px">梦溪云算<span style="font-size:38px" class="RIcon" >®</span></div>
            <div class="alignC mt10" style="font-size:28px">创新 • 赋能  推动企业数字化转型   数字资产转化为生产力</div>
        </div>
        <!-- 手机端商品展示 -->
        <div class="lineTitle fs60">云端的高性能专业计算服务</div>
        <div class="product_mobile">
            <div class="product_mobile_img_div">
                <div class="product_mobile_img">
                    <img :src="products[0].img[0]" class=" mr10"/>
                    <img :src="products[0].img[1]"/>
                </div>
                <div class="product_mobile_img">
                    <img :src="products[0].img[2]" class=" mt10 mr10"/>
                    <img :src="products[0].img[3]" class=" mt10"/>
                </div>
            </div>
            <div class="product_mobile_content alignL pr40">
                <div class="product_mobile_title font_size_30" style="display:inline-block">梦溪云算<span style="font-size:18px" class="RIcon" >®</span></div>
                <div class="product_mobile_title font_size_30" style="display:inline-block">为您提供:</div>
                <div class="product_font_style product_mobile_content_one lineHeight40">云端的高性能计算服务，为油气企业勘探开发研究的“数字化生产”提供可信赖的资源保障和软件服务。</div>
                <div class="product_font_style product_mobile_content_two lineHeight40">将油气勘探开发专业软件集成到云端的资源环境，向用户提供：</div>
                <div class="product_font_style product_mobile_content_two lineHeight40 pl40"><span class="lineDot"></span><div class="display-ib text">安全、稳定、高效的大规模高性能计算环境</div></div>
                <div class="product_font_style product_mobile_content_two lineHeight40 pl40"><span class="lineDot"></span><div class="display-ib text">自主灵活配置的资源环境（存储资源、计算资源、节点数），动态弹性扩展，及时释放</div></div>
                <div class="product_font_style product_mobile_content_two lflexRowineHeight40 pl40"><span class="lineDot"></span><div class="display-ib text">集成国内、外优秀的油气勘探、开发类专业软件商业许可，提供软件租赁服务</div></div>
                <div class="product_font_style product_mobile_content_two lineHeight40 pl40"><span class="lineDot"></span><div class="display-ib text">专业软件远程线上培训和交流服务</div></div>
                <div  class="flexRow">
                    <div class="row1">
                        <div style="background:#00B050">梦溪云算</div>
                        <div class="flexRow">
                            <div class="soft" style="background:#A0C0E5">专业软件A</div>
                            <div class="soft" style="background:#A0C0E5">专业软件B</div>
                            <div class="soft" style="background:#A0C0E5">专业软件C</div>
                        </div>
                        <div style="background:#8FAADC">数据服务、文件服务</div>
                        <div style="background:#ED7D31;margin-bottom:0"> Scale-Out Computing of AWS(soca)</div>
                    </div>
                    <div class="row2">
                        <div style="background:#FFC746">WEB端</div>
                        <div style="background:#FFC746">PC端</div>
                        <div style="background:#FFC746">移动端</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="part2">
            <div class="fs60 alignC">已上线专业软件</div>
            <div class="cardBox">
                <div class="card">
                    <div class="title alignC midTitle">地质工程一体化综合研究</div>
                    <div class="logoBox alignC"><img src="../assets/img/softLogo/img1.png" /></div>
                    <div class="desc">
                        <div class="fs12em ">DecisionSpace® Geosciences (DSG) 10ep.5</div>
                        <div class="fs12em ">Drillworks® Expert 5000.20.1</div>
                        <div class="fs12em ">Engineer's Desktop™ (EDT™)5000.15</div>
                    </div>
                </div>
                <div class="card">
                    <div class="title alignC midTitle">油藏建模研究</div>
                    <div class="logoBox alignC"><img src="../assets/img/softLogo/img2.png" /></div>
                    <div class="desc">
                        <div class="fs12em ">ColchisRPTM交互式岩石物理建模软件 2021.0</div>
                        <div class="fs12em ">ColchisFMTM智能化油藏地球物理正演系统 2021.0</div>
                        <div class="fs12em ">Colchis SmartWellTM数字化井筒图解系统 2021.0</div>
                        <div class="fs12em ">Colchis SmartPipeTM油气管道智慧管理一体化平台 2021.0</div>
                        <div class="fs12em ">PerGeos数字岩心分析及三维可视化系统 2022.1</div>
                        <div class="fs12em ">Waves阵列声波测井处理解释及三维井筒可视化系统 v1.2.5</div>
                        <div class="fs12em ">tNavigator精细油气藏高效建模数模一体化系统  v22.3</div>
                    </div>
                </div>
                <div class="card">
                    <div class="title alignC midTitle">地球物理反演和油藏描述</div>
                    <div class="logoBox alignC"><img src="../assets/img/softLogo/img3.png" /></div>
                    <div class="desc">
                        <div class="fs12em ">GeoSoftware 11.0.1 Suite</div>
                        <div class="fs12em ">Jason®</div>
                        <div class="fs12em ">HampsonRussell</div>
                        <div class="fs12em ">PowerLog</div>
                        <div class="fs12em ">RockSI</div>
                        <div class="fs12em ">InsightEarth®</div>
                        <div class="fs12em ">VelPro</div>
                        
                    </div>
                </div>
                <div class="card">
                    <div class="title alignC midTitle">地震资料处理</div>
                    <div class="logoBox alignC"><img src="../assets/img/softLogo/img4.png" /></div>
                    <div class="desc">
                        <div class="fs12em ">复杂探区近地表建模和校正系统ToModel  v11.0</div>
                    </div>
                </div>
            </div>
            
        </div>
        <div class="part3">
            <div class="fs60 alignC">专业软件测试效果</div>
            <div class="content" style="margin-bottom:-70px">
                <div class="fs12em">测试方法：叠前地质统计学油藏参数直接反演</div>
                <div class="card">
                    <div class="group alignC"><div class="key">工区面积</div><div class="value">625平方公里</div></div><div class="line"></div>
                    <div class="group alignC"><div class="key">垂向时窗</div><div class="value">240毫秒</div></div><div class="line"></div>
                    <div class="group alignC"><div class="key">地震面元</div><div class="value">20米 x 20米</div></div><div class="line"></div>
                    <div class="group alignC"><div class="key">地震道数</div><div class="value">1453811</div></div><div class="line"></div>
                    <div class="group alignC"><div class="key">数据格式</div><div class="value">32位浮点</div></div><div class="line"></div>
                    <div class="group alignC"><div class="key">输入数据容量</div><div class="value" style="line-height: 26px;">叠前部分<br/>叠加(1.6GB)x 4</div></div><div class="line"></div>
                    <div class="group alignC"><div class="key">输出成果容量</div><div class="value">单实现 20GB</div></div>
                </div>
            </div>
            <div class="content">
                <div class="fs12em">计算效率对比</div>
            <div class="table" >
                <div class="tbody thead" ><div class="tr"><div class="td td1" >工作场景</div><div class="td td2">配置</div><div class="td td3">计算时间（小时）</div></div></div>
                <div class="tbody">
                    <div class="tr">
                        <div class="td td1" >本地工作场景（单机）</div>
                        <div class="td td2" >16 CPUs 64 GB</div>
                        <div class="td td3" >800</div>
                    </div>
                    <div class="tr">
                        <div class="td td1" >本地工作场景<br/>(5机并行计算)</div>
                        <div class="td td2" >16 CPUs * 5 = 80 CPUs<br/>64GB  * 5 = 320 GB</div>
                        <div class="td td3" >160</div>
                    </div>
                    <div class="tr">
                        <div class="td td1" >梦溪云算<br/>(8xlarge机型,100机并行计算)</div>
                        <div class="td td2" >32 CPUs * 100 = 3200CPUs<br/>131GB * 100  = 13100GB</div>
                        <div class="td td3" >4.5</div>
                    </div>
                </div>
            </div>
            </div>
        </div>
        <div class="part4">
            <div class="fs40 alignC">诚邀各位油气勘探开发研究专家上线试用、体验</div>
            <div class="fs40 alignC">诚邀国内优秀专业软件开发商测试、入驻</div>
            <div class="content">
                <div class="addr">梦溪云算 https://www.mxcc.com.cn</div>
                <div class="flexRow">
                    <div class="group">
                        <div class="qrcode"><img src="../assets/img/softLogo/lis.png" /></div>
                        <div class="name">试用和测试<br/>李森</div>
                    </div>
                    <div class="group">
                        <div class="qrcode"><img  src="../assets/img/softLogo/dengj.png" /></div>
                        <div class="name">业务洽谈<br/>邓金</div>
                    </div>
                    <div class="group">
                        <div class="qrcode"><img src="../assets/img/softLogo/dub.png"  /></div>
                        <div class="name">业务咨询<br/>都邦玉</div>
                    </div>
                </div>
                <div class="company">
                    <div class="fs12em ">AWS能源行业合作伙伴</div>
                    <div class="fs12em dot">北京亿邦华能石油科技发展有限公司</div>
                    <div class=" ">Beijing Ebound Petroleum Technology Development Co., Ltd.</div>
                    <div class=" ">北京市海淀区清河镇永泰园15栋303室</div>
                    <div class="number">+86 010 8275 1499<br/>+86 186 0996 9487</div>
                    
                </div>
            </div>
        </div>
        <!-- 后台管理 -->
        <!-- <div class="product_pc">
            <div class="product_pc_one">
                <div class="fs60">梦溪云算</div>
                <div class="product_mobile_title font_size_30 mt10">是一个集成平台</div>
                <div style="margin-top: 20px" class="product_font_style">更高挑战性目标</div>
                <div style="margin-top: 10px" class="product_font_style">可见的成本控制</div>
                <div style="margin-top: 10px" class="product_font_style">更高效率</div>
                <div style="margin-top: 10px" class="product_font_style">更广泛的专家智慧</div>
            </div>
            <div class="product_pc_img_div pr40">
                <img :src="manages[0].img[0]" class="product_pc_img"/>
                <div class="product_font_style product_mobile_content_two lineHeight40"><span class="dot">·</span> 可以发起高效的大规模-超大规模复杂运算</div>
                <div class="product_font_style product_mobile_content_two lineHeight40"><span class="dot">·</span> 实现综合应用多种技术手段，解决复杂的地质和地球物理问题</div>
                <div class="product_font_style product_mobile_content_two lineHeight40"><span class="dot">·</span> 实现不同专业专家之间超时距协同配合</div>
                <div class="product_font_style product_mobile_content_two pr40 lineHeight40"><span class="dot">·</span> 将不同的专业软件集成到AWS的资源环境，向用户提供高效的、统一的、综合的、可以协同的服务</div>
            </div>
        </div>
        <div class="product_pc">
            <div class="part3_img_div">
                <img :src="spec.img" class="part3_img"/>
            </div>
            <div class="part3_one">
                <div class="fs60">技能提升</div>
                <div style="margin-top: 20px" class="product_font_style lineHeight40">向云专家学习，提高个人技能和知识，在云中构建自己的未来。</div>
                <div style="margin-top: 10px" class="product_font_style lineHeight40">我们提供培训报名及数字化在线培训课堂。数字化培训让您可以在线学习并自己掌握进度。通过课堂培训，您可以向专家讲师学习最佳实践。</div>
                <div style="margin-top: 10px" class="product_font_style lineHeight40">帮助您了解云基础知识，学习设计、构建、保护和维护分析解决方案，快速熟练使用云，构建云，帮助您提高在云中的工作效率，完成更多任务。</div>
            </div>
        </div> -->
        
        
        

    </div>
</template>
<script>
    export default {
        name: 'Index',
        data() {
            return {
                products: [
                    {
                        img: [require('../assets/img/group11.png'), require('../assets/img/group12.png'),require('../assets/img/group13.png'),require('../assets/img/group14.png'),],
                        title: "手机端商品展示 满足多规格显示",
                        content: ["商品热销分类显示 分类导航显示所有分类", "热销商品优先排序 商城首页轮播自定义",
                            "商品多规格多价格展示 商品直接在线购买支付"
                        ]
                    }
                ],
                manages: [
                    {
                        img: [require('../assets/img/group2.png')],
                        content: ["公众号菜单自定义发布 微信图文图片素材管理", "商品多规格SKU自定义上架 分类自定义设置",
                            "用户管理 用户自定义标签，了解用户消费行为",
                            "订单管理 实时掌握商品交易详情", "物流闪电发货 实时掌握物流追踪状态",
                        ]
                    }
                ],
                spec:{
                    img:require('../assets/img/group3.png'),
                },
                
                
                carousel_height: '550px',
            }
        },
        methods: {},
        mounted() {
        },
        computed: {
            //计算高度
            computeHeight() {
                let screenHeight = document.body.clientWidth;
                if (screenHeight > 415) {
                    return '550px';
                } else {
                    return '250px';
                }
            },
        },
    }
</script>

<style scoped lang="scss">
.lineDot{
    display:inline-block;
    width:12px;
    height:12px;
    background:red;
    margin-right:12px;
}
    .border_parent {
        display: flex;
        width: 1400px;
        margin: auto;
        height: auto
    }

    .slider_img {
        height: 550px;
        width: 100%;
    }

    .solve {
        display: flex;
        margin-top: 30px;
        margin-bottom: 10px
    }

    .border {
        border-radius: 6px;
        border: 1px solid #EBEEF5;
        float: left;
        width: 20%;
        height: auto;
        margin: 10px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1)
        /*background: aquamarine;*/
    }

    .border_one {
        margin-left: 0;
    }

    .border_img_parent {
        margin-top: 20px
    }

    .product_mobile_content {
        width: 54%;
        .flexRow{
            color:#fff;
            text-align:center;
            justify-content:space-around;
            margin-top:10px;
            .soft{
                border-radius:5px;
                padding:0 10px;
            }
            .row1{
                width:80%;
                >div{
                    width:100%;
                    height:30px;
                    line-height:30px;
                    border-radius:5px;
                    margin-bottom:10px;
                    
                }
            }
            .row2{
                width:20%;
                line-height:30px;
                
                margin-bottom:10px;
                display: flex;
                flex-direction: column;
                height: 150px;
                justify-content: space-around;
                padding-left:15px;
                >div{
                    border-radius:5px;
                }
            }
        }
    }
    
    .product_mobile_img {
        height: 200px;
    }
    .product_mobile_img img{
        height: 100%;
    }
    .product_mobile_title {
        font-weight: bold;
    }

    .product_pc_one {
        width: 40%;
        float: left;
        text-align: left;
        padding-left: 80px;

    }
    .part3_one {
        width: 50%;
        float: left;
        text-align: left;
        padding-right: 80px;
    }

    .font_size_30 {
        font-size: 1.875em;
    }

    .product_font_style {
        font-size: 1.25em;
    }


    .product_pc_img_div {
        width: 60%;
        float: left;
        display: block;
        text-align: left;
    }
    .part3_img_div{
        width: 50%;
        float: left;
        display: block;
        text-align: center;
    }
    .part3_img_div img{
        height: 580px;
    }
    .product_pc {
        display: flex;
        width: 1400px;
        height: 715px;
        margin: 20px auto;
        background: #F2F6FC;
        align-items: center;
        padding:0px 0;
        
    }
    .part2{
        margin: 20px auto;
        background:#F2F6FC;
        width:1400px;
        padding-top:40px;
        .cardBox{
            margin-top:20px;
            padding:20px 140px;
            display:flex;
            justify-content: space-around;
                flex-wrap: wrap;
            .card{
                width: 520px;
                height: 560px;
                background: #fff;
                border: 1px solid rgba(161,171,200,.4);
                margin-bottom: 40px;
                box-sizing: border-box;
                cursor: pointer;
                border-radius: 16px;
                &:hover{
                    box-shadow: 0 8px 30px 0 #e2e5ee;
                }
                .title{
                    line-height:60px;
                    border-bottom: 1px solid rgba(161,171,200,.4);
                }
                .logoBox{
                    img{height:70px;margin-top: 10px;}
                }
                .desc{
                    padding:0 30px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    height: 360px;
                    >div{
                        line-height:40px;
                    }
                }
                
            }
        }
    }
    .part3{
        margin: 20px auto;
        background:#F2F6FC;
        width:1400px;
        padding-top:40px;
        
        .content{
            padding:40px 60px;
            .fs12em{
                margin-bottom:15px;
            }
            .card{
                margin:20px 0;
                width:100%;
                border: 1px solid rgba(161,171,200,.4);
                box-shadow: 0 8px 30px 0 #e2e5ee;
                border-radius: 10px;
                background:#fff;
                display:flex;
                justify-content:space-around;
                .line{
                    height:64px;
                    width:1px;
                    margin:35px 0;
                    border-right:1px solid #ddd;
                }
                .group{
                    
                    width:150px;
                    height:74px;
                    margin:30px 0;

                    .key{
                        height:30px;
                        color:#919aac;
                        font-size:16px;

                    }
                    .value{
                        color:#215ae5;
                        font-size:20px;
                        height:52px;
                        line-height: 52px;
                    }
                }
            }
            .table{
                box-shadow: 0 8px 30px 0 #e2e5ee;
                width:100%;
                border:1px solid #ddd;
                border-right:none;
                border-bottom:none;
                font-size:20px;
                .thead{
                    color:#919aac;
                }
                .tbody .tr{
                    display:flex;

                    .td{
                        background:#fff;
                        line-height:24px;
                        padding:10px 20px;
                        border-bottom:1px solid #ddd;
                        border-right:1px solid #ddd;
                    }
                    .td1{
                        width:30%;
                    }
                    .td2{
                        width:50%;
                    }
                    .td3{
                        width:20%;
                    }
                }
            }
        }
    }
    .part4{
        margin: 20px auto;
        background:#F2F6FC;
        width:1400px;
        padding-top:40px;
        .content{
            padding:0 260px;
            .addr{
                padding: 20px 0 10px;
                margin: 20px 60px 20px;
                border-bottom:4px solid #8faadc; 
            }
            .flexRow{
                justify-content:center;
                text-align:center;
                .group{
                    padding:20px 40px;
                    img{
                        width:80%;
                    }
                }
            }
            .company{
                padding:30px 60px;
                line-height:30px;
                position:relative;
                .number{
                    position:absolute;
                    right:60px;
                    bottom:30px;
                }
            }
        }

    }
    .product_spec_title {
        display: flex;
        margin-top: 30px;
        margin-bottom: 10px
    }

    .product_spec_parent {
        display: flex;
        width: 1400px;
        margin: 20px auto;
        align-items: center;
        height: auto
    }

    .cooperation_parent {
        display: flex;
        width: 1400px;
        margin: 20px auto;
        align-items: center;
        height: auto
    }

    .border_spec {
        border-radius: 6px;
        border: 1px solid #EBEEF5;
        float: left;
        width: 25%;
        height: auto;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    }

    .about_me_p {
        font-size: 1.25em;
        font-weight: normal;
        width: 60%;
        text-align: left;
        margin: 10px;
    }

    .border_title {
        font-family: "PingFang SC";
        margin-top: 10px;
        font-size: 1.125em;
        font-weight: bold;
    }

    .border_content_one {
        margin-top: 20px;
        font-size: 1.125em;
    }

    .border_content_two {
        margin-top: 5px;
        font-size: 1.125em;
        margin-bottom: 25px;
    }

    .img_border {
        margin-top: 15px;
        width: 80px;
        height: 80px
    }

    .border_spec_inner_one {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 35px;
        /*background-color: aqua;*/
        height: 70px;
    }

    .border_spec_content_one {
        margin-top: 15px;
        font-size: 1.125em;
        float: left;
    }

    .border_spec_inner_last {
        /*background-color: bisque;*/
        margin-top: 15px;
        font-size: 1.125em;
        float: left;
        margin-bottom: 25px;
    }

    .about_me {
        display: flex;
        margin-top: 30px;
        justify-content: center;
        width: 100%;
    }

    .product_mobile {
        display: flex;
        width: 1400px;
        align-items: center;
        margin: 20px auto;
        height: 740px;
        background: #F2F6FC;
    }
    .lineTitle{
        width: 1400px;
        text-align: center;
        margin: 0 auto;
        padding-top: 40px;
        margin-bottom: -40px;
        margin-top: 15px;
        background: #F2F6FC;
    }

    .product_mobile_img_div {
        width: 46%;
        height: auto;

    }

    .product_mobile_content_one {
        margin-top: 20px;
    }

    .product_mobile_content_two {
        margin-top: 10px;
        display:flex;
        align-items: baseline;
        .text{
            width:calc(100% - 24px)
        }
    }

    
    /* 屏幕设备适配 max-device-width: 400px */
    @media screen and (max-device-width: 415px) {
        .slider_img {
            height: 250px;
            width: 100%;
        }

        .solve {
            display: flex;
            margin-top: 5px;
            margin-bottom: 10px
        }

        .border_parent {
            display: flex;
            width: 1400px;
            margin: auto;
            height: auto;
            flex-direction: column;
        }

        .border {
            border-radius: 6px;
            border: 1px solid #EBEEF5;
            float: left;
            width: 100%;
            height: auto;
            margin: 10px 0 auto;
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1)
            /*background: aquamarine;*/
        }

        .border_img_parent {
            margin-top: 0
        }

        .product_mobile {
            display: flex;
            width: 1400px;
            align-items: center;
            margin: 20px auto;
            height: auto;
            background: #F2F6FC;
            flex-direction: column;
            justify-content: center;
        }

        .product_mobile_content {
            width: 100%;
            margin-top: 15px;
            font-size: 24px;
        }

        .product_mobile_content_one {
            margin-top: 5px;
        }

        .product_mobile_content_two {
            margin-top: 5px;
        }

        .product_mobile_img_div {
            display: flex;
            width: 100%;
            height: auto;
            justify-content: center;
            margin-top: 10px;
            margin-bottom: 15px;
        }

        .product_mobile_title {
            font-weight: bold;
        }

        .product_pc_one {
            width: 100%;
            margin-top: 15px;

        }

        .font_size_30 {
            font-size: 1.5em;
        }

        .product_font_style {
            font-size: 1.2em;
        }

        .product_pc_img {
            height: 280px;
            width: 320px;
        }
        
        .product_pc_img_div {
            width: 100%;
            float: left;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 15px;
        }

        .product_pc {
            display: flex;
            width: 1400px;
            height: auto;
            margin: 20px auto;
            background: #F2F6FC;
            flex-direction: column;
        }

        .product_spec_title {
            display: flex;
            margin-top: 10px;
        }

        .product_spec_parent {
            display: flex;
            width: 1400px;
            align-items: center;
            justify-content: center;
            margin: 20px auto;
            height: auto;
            flex-direction: column;
        }

        .border_spec {
            float: left;
            height: auto;
            margin: 8px;
            width: 100%;
            border-radius: 6px;
            border: 1px solid #EBEEF5;
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        }

        .about_me_p {
            font-size: 1em;
            font-weight: normal;
            width: 1400px;
            text-align: left;
            margin: 5px;
        }

        .border_title {
            font-family: "PingFang SC";
            margin-top: 5px;
            font-size: 1.125em;
            font-weight: bold;
        }

        .border_content_one {
            margin-top: 5px;
            font-size: 1.125em;
        }

        .border_content_two {
            margin-top: 5px;
            font-size: 1.125em;
            margin-bottom: 8px;
        }

        .img_border {
            margin-top: 8px;
            width: 80px;
            height: 80px
        }

        .border_spec_inner_one {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 8px;
            /*background-color: aqua;*/
            height: 70px;
        }

        .border_spec_content_one {
            margin-top: 5px;
            font-size: 1.125em;
            float: left;
        }

        .border_spec_inner_last {
            /*background-color: bisque;*/
            margin-top: 5px;
            font-size: 1.125em;
            float: left;
            margin-bottom: 8px;
        }

        .about_me {
            display: flex;
            margin-top: 10px;
            justify-content: center;
            width: 100%;
        }
    }

    * {
        box-sizing: border-box;
        margin: 0;
        // padding: 0;
    }

    .border_spec_img {
        width: 75px;
        height: 55px;
        align-self: center
    }

    .font_size_24 {
        font-size: 1.5em;
    }
</style>