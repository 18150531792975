<template>
<!-- 存储 -->
  <div class="commonMain">
    <div class="alignC fs60 p40">安全、稳定，高可用的产品</div>
    <div class="p80 mainBgc relative">
      <div class="flexRow">
        <div class="fsInit">
          <div class="titBox ">S3</div>
          <div class=" wordBox mt10 ">是一种对象存储服务，提供简单、安全、高持久、可扩展、可用性高、成本低、集成化的对象存储。</div>
        </div>
        <div class="pl40 fsInit">
          <div class="titBox">EBS</div>
          <div class="fsInit  wordBox mt10">是一种易于使用的高性能数据块存储服务，可与  EC2)一起使用，处理任何规模的吞吐量和事务密集型工作负载。</div>
        </div>
      </div>
      <div class="flexColumn">
        <div class="kuohao"><img src="../assets/img/花括号.png" alt="" srcset=""></div>
        <div class="fs60">存储</div>
        <div><img class="iconImg" src="../assets/img/storage.png" alt="" srcset=""></div>
      </div>
      <img class="iconLeft" src="../assets/img/iconLeft.png" alt="" srcset="">
      <img class="iconRight" src="../assets/img/iconRight.png" alt="" srcset="">
    </div>
  </div>
</template>
<script>
    export default {
        name: 'Communication',
        data () {
            return {
            }
        }
    }
</script>

<style scoped lang="scss">
.flexRow{
  /* justify-content:start; */
}
.titBox{
  font-size: 50px;
  background-color: #2b4663;
  border-radius: 10px;
  width: 120px;
  height: 120px;
  text-align: center;
  line-height: 120px;
  color: #fff;
}
.wordBox{
  width: 500px;
  line-height: 1.6em;
  text-align: left;

}
.iconImg{
  width: 100px;
}
.iconLeft{
  left: 20px;
  position: absolute;
  bottom: 20px;
  width: 60px;
}
.iconRight{
  right: 20px;
  position: absolute;
  bottom: 20px;
  width: 60px;
}
</style>