<template>
  <div class="">
    <div id="infomation">
      <div class="title">
        <h4>企业信息</h4>
        <el-button v-if="isEdit" size="mini" @click="isEdit=false" type="primary">保存</el-button>
        <el-button v-else size="mini" @click="isEdit=true" type="text" icon="el-icon-edit">编辑</el-button>
      </div>
        <el-form ref="form" :model="form" label-width="20%" size="samll">
          <el-form-item label="企业名称:">
            <el-input  v-if='isEdit' v-model="form.companyName" ></el-input>
            <span v-else>{{form.companyName||'XX公司'}}</span>
          </el-form-item>
          <el-form-item label="统一信用代码:">
            <el-input  v-if='isEdit' v-model="form.companyId" ></el-input>
            <span v-else>{{form.companyId||'12334567890'}}</span>
          </el-form-item>
          <el-form-item label="法人姓名:">
            <el-input  v-if='isEdit' v-model="form.legalPerson" ></el-input>
            <span v-else>{{form.legalPerson||'Alice'}}</span>
          </el-form-item>
          <el-form-item label="公司地址:">
            <el-input  v-if='isEdit' v-model="form.address" ></el-input>
            <span v-else>{{form.address||'北京市朝阳区'}}</span>
          </el-form-item>
        </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      isEdit:false,
      form: {
        companyName:'XX公司',
        companyId:'12334567890',
        legalPerson:'Alice',
        address:'北京市朝阳区'
      },
    };
  },

  methods: {
    
  }
}
</script>

<style scoped lang="scss">
  #infomation{
    .title{
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
    }
  }
</style>
<style lang="scss" >
#infomation .el-form{
  overflow: hidden;
  .el-form-item{
    width: 50%;
    float: left;
  }
}

</style>