<template>
<!-- 通讯 -->
  <div class="commonMain">
    <div class="top">
      <img src="../assets/img/group5.png" alt="" srcset="">
      <div>联系我们</div>
    </div>
    <div class="content">
      <div class="mt40 alignC">需要试用或测试软件，请联系我们业务咨询或技术支持，感谢您的关注！</div>
      <div class="left p80">
        <div class=" flexRow">
          <div class="flexRow pl40" style="width:50%"><img class="mr20" src="../assets/img/phone.png" alt=""><div class="flexColumn alignItemsStart"><div class="midTitle bold">业务咨询电话</div><div class="fs20">010-82751499</div><div class="fs20">18609969487</div></div></div>
          <div class="flexRow pl40" style="width:50%"><img class="mr20" src="../assets/img/email.png" alt=""><div class="flexColumn alignItemsStart"><div class="midTitle bold">业务咨询邮箱</div><div class="fs20">dubangyu@ebhn.cn</div></div></div>
        </div>
        <div class="line"></div>
        <div class=" flexRow">
          <div class="flexRow pl40" style="width:50%"><img class="mr20" src="../assets/img/phone.png" alt=""><div class="flexColumn alignItemsStart"><div class="midTitle bold">技术咨询电话</div><div class="fs20">13999008974</div><div class="fs20">（微信同号）</div></div></div>
          <div class="flexRow pl40" style="width:50%"><img class="mr20" src="../assets/img/email.png" alt=""><div class="flexColumn alignItemsStart"><div class="midTitle bold">技术咨询邮箱</div><div class="fs20">lisen@ebhn.cn</div></div></div>
        </div>
        <div class="line"></div>
        <div class="flexRow pl40 " style="width:100%"><img class="mr20" src="../assets/img/addr.png" alt=""><div class="flexColumn alignItemsStart"><div class="midTitle bold">地址</div><div class="fs20">北京市海淀区清河镇永泰园15栋0303室</div><div class="fs20">北京亿邦华能石油科技发展有限公司</div></div></div>
        <div class="line"></div>
      </div>
      <div class="right flexColumn"><img src="../assets/img/logo.jpg" alt=""></div>
    </div>
  </div>
</template>
<script>
    export default {
        name: 'Communication',
        data () {
            return {
            }
        }
    }
</script>

<style scoped lang="scss">
  .top{
    position: relative;
    height: 450px;
  }
  .top img{
    width: 100%;
    height: 100%;
  }
  .top div{
    position: absolute;
    bottom: 60px;
    left: 200px;
    font-size: 60px;
    color: #000000;
    padding-left: 40px;
    height: 90px;
    line-height: 90px;
    border-left: 10px solid #fff;
  }
  .content{
    overflow: hidden;
    
  }
  .content .left{
    float: left;
    width: 60%;
  }
  .left img{
    width: 100px;
  }
  .content .right{
    width: 40%;
    float: right;
    height: 494px;
    align-items: center;
  }
  .right img{
    width: 450px;
  }
  .flexRow{
    justify-content: start;
  }
  .line{
    width: 100%;
    height: 1px;
    border-bottom: 1px solid #e2bd16;
    margin: 30px 0;
  }
  .fs20 {
    line-height: 30px;
}
</style>