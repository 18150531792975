import Vue from 'vue'
import Router from 'vue-router'
import Index from '../components/Index'
import About from '../components/About'
import Communication from '../components/Communication'
import Calculate from '../components/Calculate'
import Storage from '../components/Storage'
import DriverDownLoad from '../components/DriverDownLoad'
import Developing from '../components/Developing'
import Login from '../components/login'
import Register from '../components/register'
import Information from '../components/Information'
import PersonalManage from '../components/PersonalManage'

Vue.use(Router)

export default new Router({
    routes: [
        {
            path: '/',
            name: 'Index',
            component: Index
        },
        {
            path: '/about',
            name: 'About',
            component: About
        },
        {
            path: '/communication',
            name: 'Communication',
            component: Communication
        },
        {
            path: '/storage',
            name: 'Storage',
            component: Storage
        },
        {
            path: '/calculate',
            name: 'Calculate',
            component: Calculate
        },
        {
            path: '/driverDownLoad',
            name: 'DriverDownLoad',
            component: DriverDownLoad
        },
        {
            path: '/developing',
            name: 'Developing',
            component: Developing
        },
        {
            path: '/login',
            name: 'Login',
            component: Login
        },
        {
            path: '/register',
            name: 'register',
            component: Register
        },
        {
            path: '/Information',
            name: 'Information',
            component: Information
        },
        {
            path: '/PersonalManage',
            name: 'PersonalManage',
            component: PersonalManage
        },
       
    ]
})
